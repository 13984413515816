import React from 'react';
import DOMPurify from 'dompurify';

const TextToHtml = ({ text,classNm }) => {
  const createMarkup = (html) => {
    return { __html: DOMPurify.sanitize(html) };
  };

  return (
    <div className={classNm} dangerouslySetInnerHTML={createMarkup(text)} />
  );
};

export default TextToHtml;
