import React,{useState} from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import TextToHtml from './text_html';
import './icon_title.css';
import './text_thumbnail.css';
// component ref from ArticleListing
export const Text_Thumbnail_n=(props)=>{
  //alert("i m in props");
  let appUrtPath=appGetUrlPath();
  let listData=props.lsData;



  let titleTxtPadding="";
  let thumTitle="";
  if(props.thumTitle==="center"){
    thumTitle="";
  }else if(props.thumTitle==="left-one_column"){
    thumTitle="align-items:flex-start;flex-direction:row;";
    titleTxtPadding="padding-top:16px";
  }else{
    thumTitle="align-items:flex-start";
  }
  /* Check for Sorting Data */
  if(listData.length>0){
    if(listData[0]["col14"]!==""){
      listData.sort(function (a, b) {
        return parseInt(a.col14)-parseInt(b.col14);
    });
    }
  }


  let strBgImg="";
  let title="";

  let activeStyle = {
    
  };

  let inactiveStyle = {
   
  };

  if(props.lsData.length>0){
    strBgImg=props.lsData[0]["bg_img"];
    //title=lsData[0]["g_title"];
   
    if(strBgImg!==""){
        strBgImg=appUrtPath+props.lsData[0]["bg_img"];
        activeStyle["backgroundSize"]="cover";
        activeStyle["backgroundImage"]=`url(${strBgImg})`;
        //alert("i m in strbg");
      strBgImg="style=\"background-size:cover;background-image:url("+strBgImg+")\"";
    }
  }
  //let isWhite="txt-white";
  let isWhite="";

  /* custome Conditions for data */
  let customDesc="";
  let othDesc="";


  
      var strResp="";
      let hrefLink="";
      let bgAssign="";
      let vContainerType="container";

      let colDef="col-lg-6 col-md-6 col-sm-6 col-xs-6";

  return(<div style={activeStyle} class={"shop-category-area "+bgAssign+" pb-100px pt-70px"}>
    <div class={vContainerType}>
    <div class="row">
      <div class="col-md-12">
          <div class="heading page__title-bar1">
              <center><h3 class={"title "+isWhite}>{title}</h3></center>
          </div>
      </div>
      <div class="col-md-12 mb-5">
      <div class={"center heading page__title-bar1 "+isWhite}>
        <TextToHtml text={customDesc+
          othDesc} />
      </div>
  </div>
  </div>
        <div class="row">
            <div class="col-lg-12 order-lg-last col-md-12 order-md-first">
            <div class="shop-bottom-area">
              <div class="row">
            {props.lsData.map((item,index)=>{

    
if(item["col11"]!==""){
  vContainerType=item["col11"];
}

if(item["col10"]===""){
  hrefLink="blog\\"+urlTitle+"?rf="+item["e_id"];
  if(item["col7"]!==""){
   hrefLink=item["col7"];
  }
  hrefLink="javascript:void(0)";

 var imgPath="default-product.png"; 
 var urlTitle= item["col1"];
 urlTitle=urlTitle.replace("?","_");
 urlTitle=urlTitle.replace(" ","-");

 let strImgPaths="";

 
   if(item["col3"].trim()===""){
   
    if(item["col4"]!=""){
      imgPath=item["col4"];
      
      if(item["col4"].indexOf(".mp4")!==-1){
        strImgPaths="<video><source src=\""+appUrtPath+imgPath+"\" type=\"video/mp4\"></video>";
      }else{
        strImgPaths="<img class=\"flex-column_thumb\"  src=\""+appUrtPath+imgPath+"\" alt=\"img_footer\" />";
      }
       }
   
    if(item["col13"].trim()!==""){
      colDef=item["col13"].trim();
    }
       //alert();   

    strResp="<div class=\"border-box-2 border-box-1 product12 mb-5\">"+
    "<form>"+
        "<div class=\"content pd_lr-10px\">"+
           "<div class=\"flex-body_thumb pt-2 pb-2\">"+
           strImgPaths+
            "<div><h6 style=\"float:left\"><a class=\"f_color_1 flex-column_thumb\" href=\""+hrefLink+"\">"+item["col2"]+"</a></h6></div>"+
            "<span class=\"price1 heading article_thumb flex-row_thumb\">"+
            item["col3"]+
         "</span>"+
            "</div>"+
           
        "</div>"+
    "</form>"+"</div>"+
"</div>";
   }else{
    let bgAssignTmp="";
    if(item["col12"]!==""){
      bgAssignTmp=item["col12"];
      if(item["col12"]==="default-image"){
        bgAssign="bg-area1";
      }
      
    }
   
    if(item["col4"]!=""){
      imgPath=item["col4"];
      
      if(item["col4"].indexOf(".mp4")!==-1){
        strImgPaths="<video><source src=\""+appUrtPath+imgPath+"\" type=\"video/mp4\"></video>";
      }else{
        strImgPaths="<img class=\"text_thumb_img\"  src=\""+appUrtPath+imgPath+"\" alt=\"text_thumbnail\" />";
      }
       }
       if(item["col13"].trim()!==""){
        colDef=item["col13"].trim();
        
      }
    strResp="<div class=\"border-box-noshadow product12 mb-5\">"+
    "<form class=\""+bgAssignTmp+"\">"+
        "<div class=\"content pd_lr-10px\">"+
           "<div class=\"flex-body_thumb flex-column_thumb pt-2 pb-2\" style=\""+thumTitle+"\">"+
           strImgPaths+
            "<div style=\""+titleTxtPadding+"\"><h6><a class=\"f_color_1 \" href=\""+hrefLink+"\">"+item["col2"]+"</a></h6></div>"+
            "</div>"+
            "<div class=\"price1 heading article_thumb pb-2 center\">"+
            item["col3"]+
         "</div>"+
        "</div>"+
    "</form>"+"</div>"+
"</div>";
   }
  
  // bgAssign="";
//alert(strResp);
}else if(item["col10"]==="top_text"){
  title=item["col1"];
  customDesc=item["col2"];
  
  if(item["col3"]!==""){
    othDesc="<p>"+item["col3"]+"</p>";
  }

  //bgAssign=listData[i]["col12"];
  if(item["col11"]!==""){
    vContainerType=item["col11"];
  }
}
              {/* content */}
              return <TextToHtml classNm={colDef+" mx-auto"} text={strResp} />
              {/* {strResp} */}
              {/* end of conent */}
            })}
            </div>
              </div></div></div></div></div>
              )
}
export function Text_Thumbnail(lsData,thumTitle="",customCols="0"){

  //return JSON.stringify(lsData);
  var appUrtPath=appGetUrlPath();
  var listData=lsData;

  let titleTxtPadding="";
  if(thumTitle==="center"){
    thumTitle="";
  }else if(thumTitle==="left-one_column"){
    thumTitle="align-items:flex-start;flex-direction:row;";
    titleTxtPadding="padding-top:16px";
  }else{
    thumTitle="align-items:flex-start";
  }
  /* Check for Sorting Data */
  if(listData.length>0){
    if(listData[0]["col14"]!==""){
      listData.sort(function (a, b) {
        return parseInt(a.col14)-parseInt(b.col14);
    });
    }
  }


  let strBgImg="";
  let title="";
  if(lsData.length>0){
    strBgImg=lsData[0]["bg_img"];
    //title=lsData[0]["g_title"];
   
    if(strBgImg!==""){
        strBgImg=appUrtPath+lsData[0]["bg_img"];
      strBgImg="style=\"background-size:cover;background-image:url("+strBgImg+")\"";
    }
  }
  //let isWhite="txt-white";
  let isWhite="";

  /* custome Conditions for data */
  let customDesc="";
  let othDesc="";


  
      var strResp="";
      let hrefLink="";
      let bgAssign="";
      let vContainerType="container";

      let colDef="col-lg-6 col-md-6 col-sm-6 col-xs-6";

    for(var i=0;i<listData.length;i++){

      if(listData[i]["col11"]!==""){
        vContainerType=listData[i]["col11"];
      }

      if(listData[i]["col10"]===""){
        hrefLink="blog\\"+urlTitle+"?rf="+lsData[i]["e_id"];
        if(listData[i]["col7"]!==""){
         hrefLink=listData[i]["col7"];
        }
        hrefLink="javascript:void(0)";
   
       var imgPath="default-product.png"; 
       var urlTitle= lsData[i]["col1"];
       urlTitle=urlTitle.replace("?","_");
       urlTitle=urlTitle.replace(" ","-");

       let strImgPaths="";
      
       
         if(listData[i]["col3"].trim()===""){
         
          if(listData[i]["col4"]!=""){
            imgPath=listData[i]["col4"];
            
            if(listData[i]["col4"].indexOf(".mp4")!==-1){
              strImgPaths="<video><source src=\""+appUrtPath+imgPath+"\" type=\"video/mp4\"></video>";
            }else{
              strImgPaths="<img class=\"flex-column_thumb\"  src=\""+appUrtPath+imgPath+"\" alt=\"img_footer\" />";
            }
             }
         
          if(listData[i]["col13"].trim()!==""){
            colDef=listData[i]["col13"].trim();
          }
             //alert();   

          strResp+="<div class=\""+colDef+" mx-auto\">"+
          "<div class=\"border-box-2 border-box-1 product12 mb-5\">"+
          "<form>"+
              "<div class=\"content pd_lr-10px\">"+
                 "<div class=\"flex-body_thumb pt-2 pb-2\">"+
                 strImgPaths+
                  "<div><h6 style=\"float:left\"><a class=\"f_color_1 flex-column_thumb\" href=\""+hrefLink+"\">"+listData[i]["col2"]+"</a></h6></div>"+
                  "<span class=\"price1 heading article_thumb flex-row_thumb\">"+
                  lsData[i]["col3"]+
               "</span>"+
                  "</div>"+
                 
              "</div>"+
          "</form>"+"</div>"+
      "</div>";
         }else{
          let bgAssignTmp="";
          if(listData[i]["col12"]!==""){
            bgAssignTmp=listData[i]["col12"];
            if(lsData[i]["col12"]==="default-image"){
              bgAssign="bg-area1";
            }
            
          }
         
          if(listData[i]["col4"]!=""){
            imgPath=listData[i]["col4"];
            
            if(listData[i]["col4"].indexOf(".mp4")!==-1){
              strImgPaths="<video><source src=\""+appUrtPath+imgPath+"\" type=\"video/mp4\"></video>";
            }else{
              strImgPaths="<img class=\"text_thumb_img\"  src=\""+appUrtPath+imgPath+"\" alt=\"text_thumbnail\" />";
            }
             }
             if(listData[i]["col13"].trim()!==""){
              colDef=listData[i]["col13"].trim();
              
            }
         
       
         
            
            
          strResp+="<div style=\"padding-right:30px\" class=\""+colDef+" \">"+
          "<div class=\"border-box-noshadow product12 mb-5\">"+
          "<form class=\""+bgAssignTmp+"\">"+
              "<div class=\"content pd_lr-10px\">"+
                 "<div class=\"flex-body_thumb flex-column_thumb pt-2 pb-2\" style=\""+thumTitle+"\">"+
                 strImgPaths+
                  "<div style=\""+titleTxtPadding+"\"><h6><a class=\"f_color_1 \" href=\""+hrefLink+"\">"+listData[i]["col2"]+"</a></h6></div>"+
                  "</div>"+
                  "<div class=\"price1 heading article_thumb pb-2 center\">"+
                  lsData[i]["col3"]+
               "</div>"+
              "</div>"+
          "</form>"+"</div>"+
      "</div>";
         }
        
        // bgAssign="";
     //alert(strResp);
      }else if(listData[i]["col10"]==="top_text"){
        title=listData[i]["col1"];
        customDesc=listData[i]["col2"];
        
        if(listData[i]["col3"]!==""){
          othDesc="<p>"+listData[i]["col3"]+"</p>";
        }

        //bgAssign=listData[i]["col12"];
        if(listData[i]["col11"]!==""){
          vContainerType=listData[i]["col11"];
        }
      }
      
    }
    //title="";
    //bgAssign=bgAssignTmp;
    //alert(vContainerType);
    var strHeader="<div "+strBgImg+" class=\"shop-category-area "+bgAssign+" pb-100px pt-70px\">"+
    "<div class=\""+vContainerType+"\">"+
    "<div class=\"row\">"+
      "<div class=\"col-md-12\">"+
          "<div class=\"heading page__title-bar1\">"+
              "<center><h3 class=\"title "+isWhite+"\">"+title+"</h3></center>"+
          "</div>"+
      "</div>"+
      "<div class=\"col-md-12 mb-5\">"+
      "<div class=\"center heading page__title-bar1 "+isWhite+" \">"+
          customDesc+
          othDesc+
      "</div>"+
  "</div>"+
  "</div>"+
        "<div class=\"row\">"+
            "<div class=\"col-lg-12 order-lg-last col-md-12 order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            "<div class=\"row\">";
     var strFooter="</div></div></div></div></div></div>";


    return strHeader+strResp+strFooter;
}
export default Text_Thumbnail_n;
