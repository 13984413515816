import React from 'react';
import './comp_latest_update.css';
export function Execute_Lates_Slider(){
    const slider = document.querySelector(".slider");
  const slides = document.querySelectorAll(".slide");
  let currentIndex = 0;
  
  function showSlide(index) {
    if (index >= 0 && index < slides.length) {
      const offset = -index * slides[0].offsetHeight;
      slider.style.transform = `translateY(${offset}px)`;
      currentIndex = index;
    }
  }
  
  function nextSlide() {
    if (currentIndex < slides.length - 1) {
      showSlide(currentIndex + 1);
    }
  }
  
  function prevSlide() {
    if (currentIndex > 0) {
      showSlide(currentIndex - 1);
    }
  }
}
export const Comp_Lates_Update=(props)=>{
  let lsData=props.lsData;
    return(<div class="news-container">
    <div class="newscontainerhead">
        Latest Updates and Notifications
    </div>
    <div class="news_div_container">
      <div class="content_container">
        <div class="imagetitle"><i class="fa fa-sticky-note-o" aria-hidden="true"></i></div>
        <div  class="news-item"><a href="www.google.com"> Indian Navy Recruitment - 2024 Notice </a> </div>
      </div>
      <div class="content_container">
        <div class="imagetitle"><i class="fa fa-sticky-note-o" aria-hidden="true"></i></div>
        <div  class="news-item"><a href="www.google.com"> Indian Navy Recruitment - 2024 Notice </a> </div>
      </div>
      <div class="content_container">
        <div class="imagetitle"><i class="fa fa-sticky-note-o" aria-hidden="true"></i></div>
        <div  class="news-item"><a href="www.google.com"> Indian Navy Recruitment - 2024 Notice </a> </div>
      </div>
    </div>
</div>)}