import {appGetUrlPath} from '../function_lib/common_cms';
import './card_list.css';

const Card_List=(props)=>{
 let appPath=appGetUrlPath();
   
      return(
        <div class="cardsdivashtech container-fluid">
        <div class="row mt-3 pb-4">
          {props.lsData.map((item,index)=>{
             var urlTitle= item["col1"];
             urlTitle=urlTitle.replace("?","_");
             urlTitle=urlTitle.replace(" ","-");
             let clpUrl="..\\blog\\"+urlTitle+"?rf="+item["e_id"];
             if(item["col7"]!==""){
                 clpUrl="..\\"+item["col7"];
             }
             if(item["col7"]==="read_now"){
                 //alert("i m in condition "+lsData[i]["col7"]+urlTitle+"?rf="+lsData[i]["e_id"]);
                 clpUrl="..\\"+item["col7"]+"\\"+urlTitle+"?rf="+item["e_id"];
                 //alert(clpUrl);
             }

            return <div class="card col-lg-3 col-md-4 col-sm-6 mb-3  text-center" style={{"padding-":"-1"}}>
            <img class="mx-auto" src={appPath+item["col4"]} alt=""/>
            <div class="card-body mt-1">
              <p class="card-text lh-sm">{item.col1}</p>
              <svg class="card-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M16.1716 10.9999L10.8076 5.63589L12.2218 4.22168L20 11.9999L12.2218 19.778L10.8076 18.3638L16.1716 12.9999H4V10.9999H16.1716Z"></path></svg>
               <p class="text-muted">{item.col2}</p>
            </div>
        </div>
          })}
        </div>
     </div>
    );
}
export default Card_List;