import React from 'react';
import TextToHtml from './text_html';
import {appGetUrlPath} from '../function_lib/common_cms';

export const Txt_Description_n=(props)=>{
    let appPath= appGetUrlPath();
    let strResp="";
   
    //alert(lsData.length);
    let styleVHeight="";

   return(props.lsData.map((item,index)=>{
        let vHeight="auto";
        if(item["col11"]==="full_screen_length"){
            vHeight="100vh";
            styleVHeight="set-screen";
        }
 var stylBgImg="";
 //alert("check img== "+lsData[i]["col4"]);
 if(item["col4"]!==""){
    stylBgImg="<img class=\"img-responsive "+styleVHeight+" adjust-layouts-img w-100\" src=\""+appPath+item["col4"].trim()+"\" style=\"width:100%;postion:absolute\" />";
 }
 let bgAssign="";

  let strBtn="";
        if(item["col7"].trim()!==""){
            let arrStrBtn=item["col8"].split(',');
            let arrBtnUrl=item["col7"].split(',');
            for(let b=0;b<arrStrBtn.length;b++){
                strBtn+= "<a href=\""+arrBtnUrl[b]+"\" class=\"btn-transparent btn-hover-dark btn-2 pdall-10px \">"+arrStrBtn[b]+"</a>";    
            }
            
        }

 //alert("check== "+lsData[i]["col12"]);
 if(item["col12"]!==""){
    bgAssign=item["col12"];
 }   

 let divId="";
 
    divId="divind"+index;
 
 if(stylBgImg!==""){
    return(<div  class="container11" id={divId}>
            <div class="container-inner11" style={{"max-width":"100%"}}>
            <div class="row12">
            <div class="col-12" style={{"position":"relative"}} align="center1">
            <div class="">
            <img class={"img-responsive "+styleVHeight+" adjust-layouts-img w-100"} src={appPath+item["col4"].trim()} style={{"width":"100%","postion":"absolute"}} />
            </div>
            
            <div data-aos="fade-up" data-aos-duration="2000" class={"container-middle "+bgAssign+" privacy_content vertical-center-noabs_absolute section_2"}>
            
            <h2 class="title">{item["col1"]}</h2>
            <TextToHtml classNm={"mob_lenght"} text={item["col2"]} />
            <TextToHtml classNm={"mob_lenght"} text={item["col3"]} />
            {strBtn}
            </div>
            </div>
            </div>
        </div>
    </div>
)
 }else{
return(<div  id={divId} class={"border-box111 "+bgAssign+" contain-gb privacy_policy_main_area pb12-70px pt-70px"}>
      <div class="container">
          <div class="container-inner">
              <div class="row">
              <div class="col-12" align="center1">
              <div class="">
              <img class="img-responsive w-100" src={appPath+item["col4"]} style={{"visibility":"hidden","max-height":"550px"}} />
              </div>
              <div data-aos="fade-up" data-aos-duration="2000" class="privacy_content vertical-center-noabs22 section_2">
              <h2 class="title">{item["col1"]}</h2>
              {/* <p>{item["col2"]}</p>
              <p>{item["col3"]}</p> */}
              <TextToHtml classNm={"mob_lenght"} text={item["col2"]} />
              <TextToHtml classNm={"mob_lenght"} text={item["col3"]} />
              </div>
              
              </div>
              </div>
          </div>
      </div>
  </div>)
 }
    
    })
)
   


}

export default Txt_Description_n;