import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import TextToHtml from './text_html';
export const Single_Img_Msg_n=(props)=>{
    let appPath= appGetUrlPath();
    let strResp=""; 

    return(props.lsData.map((item,index)=>{
        return(<div class="border-box11 section pt-50px">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3">
                    <div class="about-left-image mb-md-30px mb-lm-30px">
                        <img src={appPath+item["col4"]} class="img-responsive w-100" />
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="about-content">
                        <div class="about-title">
                            <h2>{item["col1"]}</h2>
                        </div>
                       
                          <TextToHtml text={item["col2"]} />
                        
                          <TextToHtml text={item["col3"]} />
                        
                        
                    </div>
               </div>
            </div>
        </div>
        </div>)
}))
}
export function Single_Img_Msg(lsData){
    var appPath= appGetUrlPath();
    var strResp="";
    for(var i=0;i<lsData.length;i++){
        strResp+="<div class=\"border-box11 section pt-50px\">"+
        "<div class=\"container\">"+
            "<div class=\"row align-items-center\">"+
                "<div class=\"col-lg-3\">"+
                    "<div class=\"about-left-image mb-md-30px mb-lm-30px\">"+
                        "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\"img-responsive w-100\" />"+
                    "</div>"+
                "</div>"+
                "<div class=\"col-lg-9\">"+
                    "<div class=\"about-content\">"+
                        "<div class=\"about-title\">"+
                            "<h2>"+lsData[i]["col1"]+"</h2>"+
                        "</div>"+
                        "<p class=\"mb-6\">"+
                           lsData[i]["col2"]+
                        "</p>"+
                        "<p>"+
                        lsData[i]["col3"]+
                        "</p>"+
                    "</div>"+
               "</div>"+
            "</div>"+
        "</div>"+
        "</div>";
    }
    return strResp;

}
export default Single_Img_Msg_n;