import $ from 'jquery';
import React from 'react';
import { Link } from 'react-router-dom';
import './comp_group_page.css';
export const Comp_Group_Page=(props)=>{
    
        return(<div className="col-lg-12 order-lg-first col-md-12 order-md-last mb-md-60px mb-lm-60px">
        
               
                <div className="sidebar-widget-category center">
                    <ul class="nav nav-tabs justify-content-center scrollmenu">
                        {props.listGroups.map((item,index)=>{
                            // return( <li class="nav-item"><Link class="btn-sm btn-lg btn-primary btn-hover-dark mt-5 nav-link" to={"../"+item["url"]}>
                            //     {item["title"]}
                            //   </Link></li>)
                            return(<li class="nav-item"><a href={"../"+item["url"]} className="btn-sm btn-lg btn-primary btn-hover-dark mt-5 nav-link">{item["title"]}</a></li>)
                        })}
                       
                    </ul>    
                    
                </div>
            </div>)
    
}
export default Comp_Group_Page;